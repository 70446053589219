import { Component,  OnInit} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { Router,  ActivatedRoute,  Params,  ParamMap} from '@angular/router';
import 'rxjs/add/operator/switchMap';
// import { Observable} from 'rxjs/Observable';

import {CarService } from './car.service';
import {Car } from './car';
import {GLOBAL} from '../global';
import fontawesome from '@fortawesome/fontawesome';
import {faFacebook} from '@fortawesome/fontawesome-free-brands';

fontawesome.library.add(faFacebook);

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss'],
  providers: [CarService]
})
export class CarComponent implements OnInit {

  // @Input() searchForm: string;
  public cars;
  public baseurl: string;
  public formClick: string;
  public params;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _carService: CarService,
    private sanitizer: DomSanitizer
  ) {
    this.cars = '';
    this.baseurl = GLOBAL.url;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.params = params;
      this.getCars();
    });
  }

  getCars() {
    this._carService.getByCat(this.params).subscribe(
      result => {
        this.cars = result;
      },
      error => console.log(error)
    );
  }

  getBg(image) {
    const style = `background: url('` + this.baseurl + `images/` + image + `') no-repeat center center / cover;`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  setCar(result) {
    this.cars.push(new Car(
      result.id,
      result.ref,
      result.name,
      result.link,
      result.facebook,
      result.feature,
      result.logo,
      result.image,
      result.category,
      result.cat,
      result.scale,
      result.date_created,
      result.active,
    ));
  }

}
