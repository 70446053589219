import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'easteregg',
  template: `<div></div>`,
  styleUrls: ['./ee.component.scss'],
  host: { '(document:keyup)': '_keyup($event)' }
})
export class EeComponent {
  public title: string;
  public input: string;
  public secret: string;
  public timer: number;
  public meme: string;

  constructor(
  ) {
    this.secret = "78804949";
    this.input = '';
    this.timer = 0;
    this.meme = 'https://media.giphy.com/media/sKqPjHbGyDpny/giphy.gif';
  }

  private _keyup(event: KeyboardEvent) {
    this.input += event.which;
    clearTimeout(this.timer);
    setTimeout(() => this.input = '', 3000);
    this.checkCode();
  }

  checkCode() { 
    // console.log(this.input);
    if (this.input == this.secret) {
      $('body').append('<div id="np11" style="position: fixed;top:0;left:0;z-index:999999999999; background:#000; width: 100%; height: 100vh;"><img src="' + this.meme + '" class="img-responsive" width="100%"><div style=" position: absolute;bottom: 15px;\
      right: 15px;color: white;font-weight: bold;font-size: 3em;background: darkblue;padding: 0px 15px;opacity: 0.5;">NP11</div></div></div>');
      setTimeout(() => $('#np11').remove(), 4000);
    }
  }

}
