import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import 'rxjs/add/operator/switchMap';

import { CarService } from '../car/car.service';
import { GLOBAL } from '../global';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [CarService]
})
export class DetailComponent implements OnInit {

  baseurl;
  car;
  param;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _carService: CarService,) {
    this.baseurl = GLOBAL.url;
  }

  ngOnInit() {
    this.getParamaters();
  }

  getParamaters(){
    this._route.params.subscribe((params: Params) => {
      this.param = params['id'];
      this.getCar();
    });
  }

  getCar(){
    this._carService.getCarBy(this.param).subscribe(
      result => {
        this.car = result[0];
      },
      error => console.log(error)
    );
  }

}
