import { Component, OnInit } from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import {faChevronUp} from '@fortawesome/fontawesome-free-solid';

fontawesome.library.add(faChevronUp);

@Component({
  selector: 'totop',
  templateUrl: './totop.component.html',
  styleUrls: ['./totop.component.scss']
})
export class TotopComponent implements OnInit {

//  fontawesome.library.add(faCircle, faFacebook);
//  fontawesome.library.add(brands);

  constructor() { }

  ngOnInit() {
    window.addEventListener('scroll', this.checkTop, true);
    this.checkTop();
  }

  ngOnDestroy(){
    window.removeEventListener('scroll', this.scroll, true);
  }

  actionTop(scrollDuration){
    var scrollStep = -window.scrollY / (scrollDuration / 15),
      scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep);
        }
        else clearInterval(scrollInterval);
      }, 15);
  }

  checkTop(){
    if (window.scrollY >= 150) {
      document.getElementById('totop').style.display = "block";
    } else {
      document.getElementById('totop').style.display = "none";
    }
  }
  scroll = (): void => {
    this.checkTop();
  }
}
