import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Rutas
import { routing, appRoutingProviders } from './app.routing';

import { AppComponent } from './app.component';
import { CarComponent } from './car/car.component';
import { EeComponent } from './ee/ee.component';
import { BannerComponent } from './banner/banner.component';
import { TotopComponent } from './totop/totop.component';
import { SearchPipe } from './pipes/search.pipe';
import { SearchclickPipe } from './pipes/searchclick.pipe';
import { SearchComponent } from './search/search.component';
import { DetailComponent } from './detail/detail.component';
import { OrderByPipe } from './pipes/orderby.pipe';


@NgModule({
  declarations: [
    AppComponent,
    CarComponent,
    EeComponent,
    BannerComponent,
    TotopComponent,
    SearchPipe,
    SearchclickPipe,
    SearchComponent,
    DetailComponent,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    routing,
    BrowserAnimationsModule
  ],
  providers: [
    appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
