export class Car {
    constructor(
        public id: number,
        public ref: string,
        public name: string,
        public link: string,
        public facebook: string,
        public feature: number,
        public logo: string,
        public image: string,
        public category,
        public cat,
        public scale,
        public date_created: Date,
        public active: number
    ){
        this.cat = (cat == "0") ? { 'id': 0, 'name': 'Competition' } : { 'id': 1, 'name': 'RTR - Hobbie' };

        switch (category) {
            case "0":
                this.category = { 'id': 0, 'name': 'OffRoad' };
                break;
            case "1":
                this.category = { 'id': 1, 'name': 'OnRoad' };
                break;
            case "2":
                this.category = { 'id': 2, 'name': 'Pancar' };
                break;
        }

        switch (scale) {
            case  "0":
                this.scale = { 'id': 0, 'name': '1/8' };
                break;
            case  "1":
                this.scale = { 'id': 1, 'name': '1/10' };
                break;
            case  "2":
                this.scale = { 'id': 2, 'name': '1/12' };
                break;
            case  "3":
                this.scale = { 'id': 3, 'name': '1/24' };
                break;
        }
    }

}