import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from '../global';

@Injectable()
export class BannerService {
    public url: string;

    constructor(public _http: HttpClient) {
        this.url = GLOBAL.apiUrl;
    }
    get(): Observable<any> {
        return this._http.get(this.url + 'banner/json');
    }
}
