import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../global';
import { BannerService } from './banner.service';
import { Banner } from './banner';
import { Swiper, Navigation, Pagination, Scrollbar } from 'swiper/dist/js/swiper.esm.js';
import fontawesome from '@fortawesome/fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';

fontawesome.library.add(faChevronLeft, faChevronRight);

@Component({
  selector: 'banners',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [BannerService]
})
export class BannerComponent implements OnInit {

  public banners: Array<Banner>;
  public baseurl: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _bannerService: BannerService,
    private sanitizer: DomSanitizer
  ) {
    this.banners = [];
    this.baseurl = GLOBAL.url;
  }

  ngOnInit() {
    this.getBanners();


    setTimeout(() => {
      this.initSwiper();
    }, 1000);
  }

  initSwiper(){
    Swiper.use([Navigation, Pagination, Scrollbar]);
    var swiper = new Swiper('.swiper-container', {
      preloadImages: false,
      lazy: true,
      autoplay: true,
      loop: true,
      speed: 500,
      navigation: {
        nextEl: '.swiper-b-next',
        prevEl: '.swiper-b-prev',
      },
      effect: 'cube'
    });
  }

  getBanners() {
    this._bannerService.get().subscribe(
      result => {
        // console.log(result.length);
        for (let i = 0; i < result.length; i++) {
          this.banners.push(new Banner(
            result[i].id,
            result[i].name,
            result[i].image,
            result[i].link,
            result[i].norder,
            result[i].date_created,
          ));
        }
      },
      error => {

      }
    );
  }

  getBg(image) {
    let style = `background: url('` + this.baseurl + `images/` + image + `') no-repeat center center / cover;`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
