import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../global';

@Injectable()
export class CarService {
    public url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = GLOBAL.apiUrl;
    }

    getCars(car): Observable<any> {
        const car_type = (car === 'new') ? 'new' : 'discontinued';
        return this._http.get(this.url + 'getcars/' + car_type);
    }

    getAllCars(): Observable<any>{
        return this._http.get(this.url + 'allcars/');
    }

    searchCar(term): Observable<any> {
        return this._http.get(this.url + 'searchCar/' + term);
    }

    getCarBy(value, key = 'ref'): Observable<any> {
        const param = (key === 'ref') ? value + '/' + key : value;
        return this._http.get(this.url + 'getcarby/' + param);
    }

    getByCat(params): Observable<any> {
        // console.log(params);
        var param;
        if ((params['type'] === undefined)) {
           param = 'new/all/all';
        } else {
            const category = (params['category'] === undefined) ? 'all' : params['category'];
            const cat = (params['cat'] === undefined) ? 'all' : params['cat'];
            param = params['type'] + '/' + category + '/' + cat;
        }
        // console.log(param);
        return this._http.get(this.url + 'getbycat/' + param);
    }
}
