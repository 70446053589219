import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
@Injectable()
export class SearchPipe implements PipeTransform {
  public response;
  public items;
  constructor(){
    this.response = [];
  }

  transform(items: any, formSearch: string): any {
    this.items = items;
    if (formSearch === undefined){
      return this.items;
    }
    console.log(formSearch)
    // if (categories !== undefined){
    //   this.response = this.categoryFilter(categories);
    // }
    this.response = this.nameFilter(formSearch);
    // console.log('Form: '+formSearch);
    // console.log('Click: '+categories);

    let filters = {
      'name': formSearch,
      'ref': formSearch
    }
    // this.response = this.multiFilter(items, filters);

    console.log(this.response);

    return this.response;
  }
  multiFilter(arr: Object[], filters: Object) {
    console.log(filters);
    const filterKeys = Object.keys(filters);

    return arr.filter(eachObj => {

      return filterKeys.every(eachKey => {
        console.log(filters[eachKey])
        console.log(eachObj[eachKey])
        if (filters[eachKey] === undefined || filters[eachKey] == '') {
          return true;
        } else {
          console.log(eachObj[eachKey].includes(filters[eachKey]));
          return eachObj[eachKey].includes(filters[eachKey].toLowerCase());
        }
      });

    });
  }

  nameFilter(term) {
    return this.items.filter(item => {
      return item.name.toLowerCase().includes(term.toLowerCase());
    });
  }
  refFilter(term) {
    return this.items.filter(item => {
      return item.ref.includes(term);
    });
  }
}
