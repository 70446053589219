import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Componentes
import { CarComponent } from './car/car.component';
import { DetailComponent } from './detail/detail.component';

const appRoutes: Routes = [
    { path: '', component: CarComponent },
    { path: 'cars/', component: CarComponent },
    { path: 'cars/:type', component: CarComponent },
    { path: 'cars/:type/:category', component: CarComponent },
    { path: 'cars/:type/:category/:cat', component: CarComponent },
    { path: 'car/:id', component: DetailComponent },
    { path: '**', component: CarComponent },
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);