import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import { FormControl } from '@angular/forms';
import { CarService } from '../car/car.service';
import { Car } from '../car/car';
import { GLOBAL } from '../global';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [CarService]
})
export class SearchComponent implements OnInit {

  searchTerm: FormControl = new FormControl();
  allcars = [];
  baseurl;

  constructor(
    private _carService: CarService,
  ){
    this.searchTerm.valueChanges
      .debounceTime(400)
      .subscribe(data => {
        this._carService.searchCar(data).subscribe(
          result => {
            this.allcars = result;
            console.log(this.allcars);
          })
      })
    this.baseurl = GLOBAL.url;
  }
  
  ngOnInit(){
  }

  setValue(car) {
    console.log(car);
    this.searchTerm.reset();
    this.allcars = [];
  }

}
