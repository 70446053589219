import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchclick'
})
export class SearchclickPipe implements PipeTransform {
  public response;
  public items;
  constructor(){
    this.response = [];
  }

  transform(items: any, formClick: string): any {
    this.items = items;
    if (formClick === undefined ||formClick == ''){
      return this.items;
    }
    console.log(formClick)
    this.response = this.categoryFilter(formClick);
    return this.response;
  }
  categoryFilter(term) {
    return this.items.filter(item => {
      // console.log(item.category);
      console.log(term);
      console.log(item.category.includes(term));
      return item.category.includes(term);
    });
  }
}